import './App.scss';
import  Homepage  from './views/Homepage.js'

function App() {
  return (
    <div className="App">
     <Homepage/>
    </div>
  );
}

export default App;
